module.exports = {
    initUpload: initUpload,
    initAddReferencePayment: initAddReferencePayment,
    initEditReferencePayment: initEditReferencePayment,
    initEditReferencePaymentRow: initEditReferencePaymentRow
}

var $ = require('jquery'),
	_ = require('underscore'),
	moment = require('moment'),
    utils = require('./utils');

function initUpload() {
    console.log('INIT UPLOAD');
}

function initAddReferencePayment() {
    console.log('INIT ADD REFERENCE PAYMENT');
    initDatePicker('div_id_entry_date');
    initTimePicker('div_id_entry_time');
}

function initEditReferencePayment() {
    console.log('INIT EDIT REFERENCE PAYMENT');
    initDatePicker('div_id_entry_date');
    initTimePicker('div_id_entry_time');
}

function initEditReferencePaymentRow() {
    console.log('INIT EDIT REFERENCE PAYMENT ROW');
    $('#id_customer_ref_num').select2();
    var $tgtContainer = $('#formContainer');
    initDatePicker('div_id_payment_date');
    bindSelectRefNumberChange($tgtContainer);
    bindReferenceNumberChange($tgtContainer);
}

function initDatePicker(containerId) {
    // initialize datepicker
    $('#' + containerId + ' .datepicker_field').datetimepicker({
        locale: moment.locale(),
        format: 'L',
        calendarWeeks: true
    });
}

function initTimePicker(containerId) {
    // initialize timepicker
    $('#' + containerId + ' .timepicker_field').datetimepicker({
        locale: moment.locale(),
        format: 'HH:mm',
    });
}

/**
 * Add form into formset from empty form template.
 */
function bindAddForm(clickSelector, formset_prefix) {
    $(document).on('click', clickSelector, function(e) {       
        var form_idx = $('#' + formset_prefix + '-TOTAL_FORMS').val();
        $('#formsetContainer').append($('#emptyFormTemplate').html().replace(/__prefix__/g, form_idx));
        $('#' + formset_prefix + '-TOTAL_FORMS').val(parseInt(form_idx) + 1);
    });
}

/**
 * Mark formset form for removal.
 */
function bindRemoveForm(clickSelector) {       
    $(document).on('click', clickSelector, function(e) {
        var $form = $(this).closest('.formset-form');
        $(".delete-formset-form", $form).prop('checked', true);
        $form.fadeOut();
    });
}

// existing reference number selected
function bindSelectRefNumberChange($tgtContainer) {
    $tgtContainer.on('change', '#id_customer_ref_num', function() {
        var customer_ref_num = $(this).val();
        console.log(customer_ref_num);
        if(customer_ref_num) $('#id_new_ref_num').val('');
    });
}

function referenceNumberChanged(url) {
    var ajaxReq = $.ajax({
        dataType: 'json',
        type: 'GET',
        url: url
    });
    ajaxReq.done(function ( data, textStatus, jqXHR ) {
        $('#id_customer').val(data.customer_id).trigger('change.select2');
    });
}

// new reference number changed
function bindReferenceNumberChange($tgtContainer) {
    $tgtContainer.on('change', '#id_reference_number', function() {
        var url = $tgtContainer.data('customer-url'),
            ref_number = $(this).val();
        url += ref_number;
        referenceNumberChanged(url);
    });
}
