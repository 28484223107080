var $ = require('jquery'),
	csrf = require('./lib/helpers/csrf');

// init helpers
csrf();  // Django CSRF

/**
 * App modules
 */
var utils = require('./lib/utils'),
	customers = require('./lib/customers'),
	payments = require('./lib/payments');

/**
 * Premium Group public app
 */
var App = {
	utils: utils,
	customers: customers,
    payments: payments
};

window.App = App;
window.jQuery = window.$ = $;

require('bootstrap');
