'use strict';

/**
 * Utility functions
 */

var $ = require('jquery'),
    _ = require('underscore'),
    select2 = require('select2'),
    Handlebars = require('handlebars'),
    moment = require('moment'),
    datetimepicker = require('eonasdan-bootstrap-datetimepicker');

// see: http://momentjs.com/docs/#/use-it/browserify/
require('moment/locale/fi');

// templates to be precompiled
var templates = [
        'alert',
        'info',
        'error'
    ],
    hbsSources = {},
    hbsTemplates = {},
    PubSub;

module.exports = {

    init: function(locale) {
        console.log('INIT UTILS');
        if(_.isUndefined(locale)) locale = 'en';
        moment.locale(locale);
        $.fn.select2.defaults.set("theme", "bootstrap");
        this.preCompileTemplates();
        moment.locale(locale);
        this.bindSetLanguage();
    },

    bindSetLanguage: function() {
        $(document).on('change', 'form.set-language #id_language', function(e) {
            var $form = $(this).closest('form');
            $form.submit();
        });
    },

    timestamp: function() {
        return moment().valueOf();
    },

    preCompileTemplates: function() {
        var self = this;
        _.each(templates, function(template, index, list) {
            hbsSources[template] = $("#" + template + "-template").html();
            hbsTemplates[template] = Handlebars.compile(hbsSources[template]);
        });
    },

    getHbsTemplate: function(templateName, context, partial_name, partial_id) {
        if(!_.isUndefined(partial_name) && !_.isUndefined(partial_id)) {
            Handlebars.registerPartial(partial_name, $("#" + partial_id + "-partial").html());
        }
        var template = hbsTemplates[templateName];
        return template(context);
    },

    /**
     * Pad a number with leading zeros to "pad" places:
     *
     * @param number: The number to pad
     * @param pad: The maximum number of leading zeros
     */
    padNumberMath: function(number, pad) {
        var N = Math.pow(10, pad);
        return number < N ? ("" + (N + number)).slice(1) : "" + number
    },

    preventSubmitOnEnter: function() {
        // allow enter in .allow-linebr textareas
        $(window).keydown(function(event) {
            if( (event.which== 13) && (!$(event.target).hasClass('allow-linebr')) ) {
                event.preventDefault();
                return false;
            }
        });
    },

    showError: function($tgtContainer, error_text) {
        var context = {error_text: error_text};
        var html = App.utils.hbsTemplates.error(context);
        $tgtContainer.append(html);
    },

    showAjaxSpinner: function(tgtContainer, circular) {
        if(_.isUndefined(circular)) circular = false;
        if (circular) {
            $(tgtContainer).addClass('ajax-loading').html('<i class="ajax_loader fa fa-circle-o-notch fa-spin"></i>');
        } else {
            var ajaxImg = "premium_group/img/loading.gif";
            $(tgtContainer).addClass('ajax-loading').html('<img class="ajax_loader" src="' + STATIC_URL + ajaxImg + '" />');

        }
    },

    hideAjaxSpinner: function(tgtContainer) {
        $(tgtContainer).find('.ajax_loader').remove();
        $(tgtContainer).removeClass('ajax-loading');
    },

    showSubmitSpinner: function($tgtContainer, spinnerClass) {
        if(_.isUndefined(spinnerClass)) spinnerClass = '.submit-spinner';
        $(spinnerClass, $tgtContainer).addClass('ajax-loading').html('<i class="ajax_loader fa fa-cog fa-spin"></i>');
    },

    generateMessages: function(messages) {
        var self = this,
            $msgsContainer = $('#userMessages');
        $msgsContainer.hide();
        _.each(messages, function(message, index, list) {
            var context = {message: message.msg };
            if (message.type == 'error') {
                // show error
                var html = hbsTemplates.alert(context);
            } else {
                // show success
                var html = hbsTemplates.info(context);
            }
            $msgsContainer.append(html);
        });
        $msgsContainer.fadeIn(500, function() {
            self.fadeOutUserMessages();
        });
    },

    fadeOutUserMessages: function(delay) {
        if(_.isUndefined(delay)) delay = 3000;
        window.setTimeout(function() {
            $("#userMessages .alert").fadeTo(1500, 0).slideUp(500, function(){
                $(this).remove(); 
            });
        }, delay);        
    },

    confirmDelete: function(btn_class) {
        var self = this;
        $(document).on('click', '.' + btn_class, function(e) {
            e.preventDefault();
            var url = $(this).data('url');
            if (!url) url = $(this).attr('href');
            var $confirmDelete = $(this).find('.confirm-delete');
            if($confirmDelete.is(":visible")) {
                if(url) window.location.href = url;
            } else {
                $confirmDelete.show();
                setTimeout(function () {
                    $confirmDelete.fadeOut();
                }, 3000);
            }
            return false;
        });
    },

    confirmModalDelete: function(btn_class, redirect_url) {
        var self = this;
        $(document).on('click', '.' + btn_class, function(e) {
            e.preventDefault();
            var url = $(this).data('url'),
                $confirmDelete = $(this).find('.confirm-delete');
            if (!url) url = $(this).attr('href');
            if($confirmDelete.is(":visible")) {
                if(url) {
                    var ajaxReq = $.ajax({
                        dataType : 'json',
                        type : 'POST',
                        url : url
                    });
                    ajaxReq.done(function ( data, textStatus, jqXHR ) {
                        window.location.href = redirect_url;
                    });
                }
            } else {
                $confirmDelete.show();
                setTimeout(function () {
                    $confirmDelete.fadeOut();
                }, 3000);
            }
            return false;
        });
    },

    /**
     * Mark formset form for removal.
     */
    bindRemoveForm: function(clickSelector) {       
        $(document).on('click', clickSelector, function(e) {
            var $form = $(this).closest('.formset-form');
            $(".delete-formset-form", $form).prop('checked', true);
            $form.fadeOut();
        });
    },

    /**
     * Add form into formset form empty form template.
     * @param {String} form_id
     */
    bindAddForm: function(clickSelector, formset_prefix, $formsetContainer, callbackFn) {
        if(_.isUndefined($formsetContainer)) $formsetContainer = $('#manageFormsetContainer');
        $(document).on('click', clickSelector, function(e) {
            var form_idx = $('#' + formset_prefix + '-TOTAL_FORMS').val();
            $formsetContainer.append($('#emptyFormTemplate').html().replace(/__prefix__/g, form_idx));
            $('#' + formset_prefix + '-TOTAL_FORMS').val(parseInt(form_idx) + 1);
            if(!_.isUndefined(callbackFn)) callbackFn();
        });
    },

    /**
     * Display modal form.
     */
    showModalForm: function($baseContainer, form_html, form_title, submit_txt, cancel_txt, delete_url, confirm_txt, help_txt, related_items) {
        var self = this;
        var context = {
                form_html: form_html, 
                form_title: form_title, 
                submit_txt:submit_txt, 
                cancel_txt:cancel_txt,
                delete_url: delete_url,
                confirm_txt:confirm_txt,
                help_txt:help_txt,
                related_items:related_items
            },
            html = self.getHbsTemplate('modal_form', context);
        $baseContainer.append(html);
    },

    updateFormSelect: function(selectedVals, target_id, target_url, $parent) {
        var self = this;
        if(target_url) {
            // append selected values to base url
            _.each(selectedVals, function(urlId, index, list) {
                target_url += urlId + '/';
            });
            var ajaxReq = $.ajax({
                dataType : 'json',
                type : 'GET',
                url : target_url
            });
            ajaxReq.done(function ( data, textStatus, jqXHR ) {
                var context = {select_objects: data.select_objects};
                if(data.texts) context['texts'] = data.texts;
                var html = self.getHbsTemplate('select_options', context);
                if($parent) {
                   $(target_id, $parent).html(html);
                } else {
                    $(target_id).html(html);
                }
            });
        }
    },

    bindSubmitJsonForm: function(formId, $tgtContainer, publish_success) {
        var self = this;
        $tgtContainer.off('submit', formId , { tgtContainer: $tgtContainer, publish_success:publish_success }, self.submitJsonForm);
        $tgtContainer.on('submit', formId, { tgtContainer: $tgtContainer, publish_success:publish_success }, self.submitJsonForm);
    },

    submitJsonForm: function(e) {
        e.preventDefault();
        var self = App.utils;
        var $tgtContainer = e.data.tgtContainer,
            url = $(this).attr('action'),
            formData = $( this ).serializeArray(),
            publish_success = e.data.publish_success;
        if($tgtContainer && url) {
            $('.disable-on-submit', $tgtContainer).prop('disabled', true);
            self.showSubmitSpinner($tgtContainer);
            var ajaxReq = $.ajax({
                type: "POST",
                data: formData,
                url : url
            });
            ajaxReq.done(function ( data, textStatus, jqXHR ) {
                $('.disable-on-submit', $tgtContainer).prop('disabled', false);
                $tgtContainer.html(data.form_html);
                self.generateMessages(data.messages);
                if(data.success) {
                    if(publish_success) {
                        // publish success event for subscribers
                        PubSub.publish(publish_success);
                    }
                }
            });
        }
    },

    bindFormFieldChange: function($form, source_id, target_url, target_id, clear_ids, extra_pre_fields) {
        var self = this;
        $form.on('change', source_id, function() {
            var selectedVal = this.value;
            self.emptyFields(clear_ids, $form);
            if(selectedVal && target_url) {
                var selectedVals = [];
                _.each(extra_pre_fields, function(extra_pre_field, index, list) {
                    var field_val = $(extra_pre_field, $form).val();
                    selectedVals.push(field_val);
                });
                selectedVals.push(selectedVal);
                self.updateFormSelect(selectedVals, target_id, target_url, $form);
            }
        });
    },

    bindFormsetFieldChange: function(field_id, target_url, target_id, clear_ids, extra_pre_fields) {
        var self = this;
        $(document).on('change', field_id, function() {
            var $parent = $(this).closest('.formset-row'),
                selectedVal = this.value;
            self.emptyFields(clear_ids, $parent);
            if(selectedVal && target_url) {
                var selectedVals = [];
                _.each(extra_pre_fields, function(extra_pre_field, index, list) {
                    var field_val = $(extra_pre_field, $parent).val();
                    selectedVals.push(field_val);
                });
                selectedVals.push(selectedVal);
                self.updateFormSelect(selectedVals, target_id, target_url, $parent);

            }
        });
    },

    emptyFields: function(field_ids, $parent) {
        if(!_.isUndefined(field_ids)) {
            if(_.isArray(field_ids)) {
                // multiple fields
                _.each(field_ids, function(field_id, index, list) {
                    if($parent) {
                        $(field_id, $parent).html('');
                    } else {
                        $('#' + field_id).html('');
                    }
                });
            } else {
                // single field
                if($parent) {
                    $(field_ids, $parent).html('');
                } else {
                    $('#' + field_ids).html('');
                }
            }
        }
    },

    initHelpPopovers: function() {
        $('.popover-help').hover(function() {
            $( this ).popover({
                html : true,
                title: function() {
                  return $(this).children('.popover-head').html();
                },
                content: function() {
                  return $(this).children('.popover-content').html();
                },
                container: 'body',
                placement: 'auto left'
            }).popover('show');
        }, function() {
            $( this ).popover('hide');
        });
    },

    /**
     * Get container spesific items html via ajax (with paging).
     * @param {jQuery} $tgtContainer
     * @param {String} url
     * @param {PlainObject|String|Array} formData
     * @param {Function} getItemsCallback
     */
    getItems: function($tgtContainer, url, formData, getItemsCallback, $totalCountContainer) {
        var self = this;
        if(_.isUndefined(url)) url = $tgtContainer.data('url') + '1/';
        if(_.isUndefined(formData)) formData = null;        
        self.showAjaxSpinner($tgtContainer, true);
        var ajaxReq = $.ajax({
            dataType : 'html',
            type : 'GET',
            url : url,
            data: formData
        });
        ajaxReq.done(function ( data, textStatus, jqXHR ) {
            self.hideAjaxSpinner($tgtContainer);
            $tgtContainer.hide().html(data).fadeIn();
            self.initPaging($tgtContainer, getItemsCallback);
            self.bindOrderBy($tgtContainer, getItemsCallback);
            self.initCollapse($tgtContainer);
            self.initSelectAll($tgtContainer);
            self.bindSelectableChange($tgtContainer);
            self.setSelectedOnPage($tgtContainer);
            if($totalCountContainer) {
                // display total item count
                var totalCount = $('.pagination', $tgtContainer).data('total-count');
                if(!_.isUndefined(totalCount)) {
                    $('.item-count', $totalCountContainer).text(totalCount);
                    $totalCountContainer.fadeIn();
                } else {
                    $('.item-count', $totalCountContainer).empty();
                    $totalCountContainer.hide();
                }
            }

        });
    },

    bindEditItem: function($tgtContainer, initTarget) {
        $tgtContainer.off('click', initTarget);
        $tgtContainer.on('click', initTarget, function(e) {
            e.stopPropagation();
            var url = $(this).data('url');
            if(url) window.location.href = url;
        });
    },

    /**
     * Initialize ordeing for items.
     * @param {jQuery} $tgtContainer
     * @param {Function} getItemsCallback
     */
    bindOrderBy: function($tgtContainer, getItemsCallback) {
        var self = this;
        $tgtContainer.off('click', '.order-header');
        $tgtContainer.on('click', '.order-header', function(e) {
            e.preventDefault();
            var url = $(this).attr('href');
            getItemsCallback(url);
        });
    },

    /**
     * Initialize paging for items.
     * @param {jQuery} $tgtContainer
     * @param {Function} getItemsCallback
     * @param {Array} pre_url_attrs additional (before url) callback attributes as array
     */
    initPaging: function($tgtContainer, getItemsCallback, pre_url_attrs) {
        var self = this;
        $('.pagination a', $tgtContainer).click(function(e) {
            e.preventDefault();
            var url = $(this).attr('href');
            if(_.isArray(pre_url_attrs)) {
                getItemsCallback(pre_url_attrs.toString(), url);
            } else {
                getItemsCallback(url);
            }
        });
    },

    /**
     * Reset list selections to empty
     */
    resetSelections: function($tgtContainer) {
        var $editButton = $('.edit-items');
        $editButton.attr('data-selected-ids', '').hide();
        $('.item-count', $editButton).text('');
        $('.select-all-input, .selectable-item', $tgtContainer).prop('checked', false);
    },

    /**
     * Update stored item ids based on current page's selections
     */
    updateStoredItemIds: function($tgtContainer) {
        var $editButton = $('.edit-items'),
            strPrevSelectedIds = $editButton.attr('data-selected-ids'),
            prevSelectedIds = [],
            remainingIds = [],
            checkedIds = [],
            uncheckedIds = [];
        // get all unchecked item ids on current page
        $('.selectable-item:not(:checked)', $tgtContainer).each(function(){
            uncheckedIds.push($(this).val());
        });
        // get all checked item ids on current page
        $('.selectable-item:checked', $tgtContainer).each(function(){
            checkedIds.push($(this).val());
        });
        // remove unchecked ids
        if(uncheckedIds) {
            if (strPrevSelectedIds != '') {
                prevSelectedIds = strPrevSelectedIds.split(',');
            }
            if(prevSelectedIds) {
                // remove all unchecked items from id storage
                remainingIds = _.difference(prevSelectedIds, uncheckedIds);
            }
        }
        // add checked ids
        if(checkedIds) {
            remainingIds = _.union(remainingIds, checkedIds);
        }
        $editButton.attr('data-selected-ids', remainingIds.toString());
        this.updateEditButton($tgtContainer);
    },

    updateEditButton: function($tgtContainer) {
        var $editButton = $('.edit-items'),
            strSelectedIds = $editButton.attr('data-selected-ids'),
            selectedIds = [];
        if (strSelectedIds != '') selectedIds = strSelectedIds.split(',');
        $('.item-count', $editButton).text(selectedIds.length);
        if(selectedIds.length > 0) {
            $editButton.show();
        } else {
            $editButton.hide();
        }
    },

    /**
     * Set previously selected list items on page change.
     */
    setSelectedOnPage: function($tgtContainer) {
        var self = this,
            $editButton = $('.edit-items');
        if($editButton.length) {
            var prevSelectedIds = $editButton.attr('data-selected-ids').split(',');
            $('.selectable-item', $tgtContainer).each(function(e) {
                if(_.contains(prevSelectedIds, $(this).val())) {
                    $(this).prop('checked', true);
                }
            });
        }
    },

    bindSelectableChange: function($tgtContainer) {
        var self = this;
        $('.selectable-item', $tgtContainer).change(function(e) {
            self.updateStoredItemIds($tgtContainer);
        });
    },

    initSelectAll: function($tgtContainer) {
        var self = this;
        $('.select-all', $tgtContainer).click(function(e) {
            if($('.select-all-input', this).is(':checked')) {
                $('.selectable-item', $tgtContainer).prop('checked', true).first().change();
                $('.select-all-input', this).prop('checked', true);
            } else {
                $('.selectable-item', $tgtContainer).prop('checked', false).first().change();
                $('.select-all-input', this).prop('checked', false);
                self.updateStoredItemIds($tgtContainer);
            }
        });
    },

    toggleChevron: function(e) {
        $(e.target)
            .prev('tr')
            .find(".collapse-toggle>i.fa")
            .toggleClass('fa-angle-double-down fa-angle-double-up');
    },

    /**
     * Activate chevron toggle.
     */
    initCollapse: function($tgtContainer) {
        var self = this;
        $($tgtContainer).on('hidden.bs.collapse', self.toggleChevron);
        $($tgtContainer).on('shown.bs.collapse', self.toggleChevron);
    }

};
