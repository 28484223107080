module.exports = {
    initCustomerForm: initCustomerForm,
    initCreateReferenceNumber: initCreateReferenceNumber
}

var $ = require('jquery'),
	_ = require('underscore'),
	moment = require('moment'),
    utils = require('./utils');

function initCustomerForm() {
    console.log('INIT CUSTOMER FORM');
    utils.bindAddForm('#addReferenceNumber', 'id_referencenumber_set', $('#formsetContainer'));
    utils.bindRemoveForm('.remove-reference-number');
}

function initCreateReferenceNumber() {
    console.log('INIT CREATE REFERENCE NUMBER');
    $('#id_existing_customer').select2();

}
